$formWidth: 950;
.login_Sign {
    @include flex($justifyContent:inherit);
    flex-direction: row;
    .react-date-picker {
        display: block;
        .react-date-picker__wrapper {
            border: none;
            background-color: #f1f1f1;
            border-radius: 3px;
            padding: 0px 12px;
        }
        input , select {
            border: none;
            background-color: transparent;
            border-radius: 0;

        }
    }
    & > div {
        height: 100%;
        min-height:660px;
        img {
            width: 100%;
            margin: -1px 0 0 -1px;
            @media (max-width:991px) {
                margin: 0;
            }
        }
        &.loginSign_right {
            max-width: 50%;
            @media (max-width:991px) {
                min-width: 50%;
            }
            @media (max-width:767px) {
                width: 100%;
                min-width: 100%;
               }
        }
        &.loginSign_left {
           @media (max-width:991px) {
            width: 50%;
           }
           @media (max-width:767px) {
            width: 100%;
           }
        }
        .loginSign_wraper {
            padding: 15px 30px;
        }
        @media (max-width:991px) {
            min-height:auto;
        }
    }
    h2 {
        border-color: transparent;
        color: $base-color;
        margin: 0;
        @media (max-width:991px) {
            font-size: 30px;
        }
    }
    h5 {
        font-size: 20px;
        color: lighten($black, 30);      
        .close {
            float: none;
            font-size: 17px;
            outline: none;
            font-weight: 500;
            color: $blue;
            text-decoration: underline;
            opacity: 1;
        }
    }
    form {
        margin-top: 15px;
    }
    label {
        margin-bottom: 2px;
        font-size: 18px;
    }
    input , select {
        height: 50px;
        border:2px solid $gray;
        border-radius: 3px;
        background-color: $gray;
        &:focus {
            border-color: $base-color;;
            outline-color:  $base-color;
            box-shadow: none;
            background-color: $gray;
        }
    }
    .form_logSignSubmit {
        @include oswald($textcolor:$white);
        padding: 8px 40px;
        background-color: $base-color;
        font-size: 20px;
        margin:15px 0 20px 0;
        transition: $transititon;
        &:focus, &:hover {
            box-shadow: none;
            outline: none;
            background-color: $black;
        }
    }
}
.modal-dialog {
    max-width: 95%;
    width: $formWidth + px;
}
.modal-body {
    padding: 0;
}
.modal-content {
    border-radius: 0;
}
.modal {
    background-color:rgba($base-color, 0.5);
    &.common_form {
        .modal-dialog {
            width: $formWidth / 2 + px;
        }
        .login_Sign {
            flex-direction: column;
        }
        .loginSign_right {
            max-width: 100%;
            @include flex;
            h2 {
                text-align: center;
                
            }
        }
    }
    
}

.step {
    display: none;
  }
  .step.active {
    display: block;
  }
  .form_logSign {
    .btn--group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
  
    }
    .btn {
      background-color: #23945f;
      color: #fff;
      padding: 8px 25px;
      transition: all 0.4s;
      &:hover {
        background-color: #000;
      }
    }
   
    input[type='checkbox'] {
      height: auto;
    }
  }

  #signupForm {
    .login_Sign {
        align-items: stretch;
        justify-content: normal;
        .loginSign_left {
            height: auto;
            min-height: auto;
            .loginSign_thumb {
                height: 100%;
                img {
                    height: calc(100% + 2px);
                    object-fit: cover;
                }
            }
        }
    }
  }