.cc_network {
    @extend %textCompound;
    margin-bottom: -6rem;
    padding: 0;
    p {
        font-size: 100%;
        color: lighten($black, 30);
        line-height: 1.7;
        margin: 0;
    }
    .cc_network_col {
        height: 100%;
        padding-bottom: 5rem;
        @media (max-width:991px) {
           margin: 0;
           padding: 0;
            
        }
    }
    img {
        margin-bottom: 2rem;
    }
    @media (max-width:991px) {
        &>div {
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            &:not(:last-child){            
            border-bottom: 1px solid lighten($black, 90);
            }
        }
    }
}
.networkTop {
    & > * {
        text-align-last: left;
        &:not(:first-child) {
            font-size: 25px;
            line-height: 1.7;
        }
    }
}