
.main_nav {
    background-color: $base-color;
    .navbar-brand {
        img {
            width: 55px;
        }
    }
    .navbar-nav {
        margin-left: auto;
        .nav-item {
            padding: 0 0.5rem;
            .nav-link {
                padding: 10px 1rem;
                @include oswald($textcolor: $white, $fontweight:500);
                &[data-toggle="modal"] {
                    font-weight: 600;
                }
            }
            &.sign_upNav {
                .nav-link {
                    background-color: darken($base-color, 9);
                }
            }
        }
    }
@media (max-width:991px) {
    .navbar-collapse {
        margin-top: 10px;
        &.collapse {
            border-top: 1px solid darken($base-color, 9);
        }
        
    }
    .navbar-toggler {
        padding: 0;
        border: 0;
        &:focus {
            outline: none;
        }
    }
}
}


.nav-new .dropdown> .dropdown-menu{
	padding: 0 0;
	border-radius: 0px;
}
.nav-new .dropdown> .dropdown-menu a{
	border-bottom: 1px solid #23945f;
	font-size: 14px;
}
.nav-new .dropdown> .dropdown-menu a{
	transition: all 0.5s;
}
 .nav-new .dropdown> .dropdown-menu a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #23945f;
    
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
        top: 60px;
    }
    }