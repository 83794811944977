%textCompound {
    text-align: justify;
    text-align-last: center;
    padding: 20px;
    @media (max-width:991px) {
        text-align: center;
        text-align: center;
        padding: 20px 15px;
    }
    p {
        font-size: 20px;
        line-height: 1.3;
        @media (max-width:991px) {
            font-size: 16px;
        }
    }
    h1, h2, h3, h4, h5, h6 {
    color:$base-color;
    border-color: transparent;
    margin: 0;
    text-align: center;
    }    
}

%morelink {
position: absolute;
right: 0;
top: 2px;
color: $base-color;
font-weight: 600;
@media (max-width:767px) {
    top: auto;
    right: auto;
    bottom: 0;
    margin-top: 15px;
}
}