// @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "utilities/_index";
@import "components/_index";
@import "pages/_index";

/* banner */
.hm_banner {
  img {
    width: 100%;
    @media (max-width: 767px) {
      @include imgCover($objectposition: center);
      height: 250px;
    }
  }
}
.logoBanner {
  text-align: center;
  margin-bottom: -2rem;
  &--wraper {
    border-bottom: 6px solid $base-color;
    padding: 3rem 0;
  }
}

.policy--modal .modal-body .modal-body-ct {
  padding: 20px 30px;
  @media (max-width:767px) {
    padding: 20px 15px;
  }
}

.rsvp_alert_model .modal-body .alert_note {
  padding: 20px 30px;
  @media (max-width:767px) {
    padding: 20px 15px;
  }
}
.rsvp_alert_model .modal-dialog {
  max-width: 80%;
  margin : 1.75rem auto
}

// .policy--modal .modal-body {
//   @media (max-width:767px) {
//     height: calc(100vh - 200px) !important;
//   }
// }

.container span .show-more-less-clickable {
  cursor: pointer;
  color: blue;
}

.user--details--show {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  .user--details--item {
    display: flex;
    gap: 15px;
    border-bottom: 2px solid #23945f;
    padding: 10px 0;

    span:first-child {
      font-weight: 600;
    }
  }
}

.rightWrap .form-group .form-check-input {
  width: 17px;
  height: 17px
}
#signupForm.show {
  overflow-y: auto;
  padding-left: 0px !important;
}

.session-out-model {
  width: 550px !important ;
   h5 {
    padding: 16px 12px;
    text-align: center;
   };
   button {
    width: 80px
   }
}

.react-datepicker-wrapper {
  width: 100% !important;
}
